import { useQueryClient } from '@tanstack/react-query';
import { Anchor, Icon, Referenz, Spacer, TabTable } from 'components/atoms';
import React, { useState } from 'react';
import { ReferenzType } from 'types';
import { Subscription } from 'types/user';
import { partition } from 'utils/functions';
import { useTranslation, useUserTier } from 'utils/hooks';
import { useToggleFollowProfileMutation } from 'utils/hooks/mutations';
import { useUserQuery } from 'utils/hooks/queries';

import { QuickSubscribe } from '../index';

import css from './SubscriptionList.module.scss';

interface SubscriptionListProps {}

const SubscriptionList: React.FC<SubscriptionListProps> = () =>
{
  const { t, r } = useTranslation(['common', 'user', 'formats', 'branchen']);
  const queryClient = useQueryClient();
  const { data: user } = useUserQuery();

  const tier = useUserTier();

  const mutation = useToggleFollowProfileMutation({
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['user'] }),
  });

  const [activeTab, setActiveTab] = useState('unternehmen');

  const [unternehmen, personen] = partition(user?.subscriptions || [], (sub) => sub.ref.type === ReferenzType.Unternehmen || sub.ref.type === ReferenzType.AusländischesUnternehmen)
    .map(subs => subs.sort((a, b) => a.ref.name.localeCompare(b.ref.name)));

  function renderUnternehmenRow(row: Subscription)
  {
    return (
      <Referenz key={row.ref.id} data={row.ref}>
        {({ ref, href }) => (
          <tr>
            <td><Anchor href={href}>{ref.name}</Anchor></td>
            <td>{[row.plz, ' ', row.ort]}</td>
            <td>
              {row.branchen ? (row.branchen.length == 1 ? (
                t(`branchen:list.${row.branchen[0]}`)
              ) :
                (<ul style={{ margin: 0, paddingLeft: '1rem' }}>
                  {row.branchen.map(branchenCode => [branchenCode, t(`branchen:list.${branchenCode}`)]).sort((a, b) =>a[1].localeCompare(b[1])).map(([branchenCode, text]) => (<li key={branchenCode}>{text}</li>))}
                </ul>)
              ) : null
              }
            </td>
            <td>
              <Anchor onClick={() => mutation.mutate({ id: ref.id, type: ref.type, wantsToFollow: false })}>
                {t('user:subscriptions.actions.unsubscribe')}
              </Anchor>
            </td>
            <td className="text-no-break">
              <Anchor href={href}>
                {t('user:subscriptions.actions.goToProfile')}
                <Icon name="chevronRight" className="inline" />
              </Anchor>
            </td>
          </tr>
        )}
      </Referenz>
    );
  }

  function renderPersonenRow(row: Subscription)
  {
    return (
      <Referenz key={row.ref.id} data={row.ref}>
        {({ ref, href }) => (
          <tr>
            <td><Anchor href={href}>{ref.name}</Anchor></td>
            <td>{row.geburtsdatum ? t('formats:date', { value: row.geburtsdatum }) : t('common:profiles.noBirthdate')}</td>
            <td>
              <Anchor onClick={() => mutation.mutate({ id: ref.id, type: ref.type, wantsToFollow: false })}>
                {t('user:subscriptions.actions.unsubscribe')}
              </Anchor>
            </td>
            <td className="text-no-break">
              <Anchor href={href}>
                {t('user:subscriptions.actions.goToProfile')}
                <Icon name="chevronRight" className="inline" />
              </Anchor>
            </td>
          </tr>
        )}
      </Referenz>
    );
  }

  return (
    <div className={css.container}>

      <div className="row space wrap">
        <p className='text-max-width'>{r('user:subscriptions.description')}</p>
        {tier === 'plus' ? (
        <Anchor
          variant='primary'
          href='/user#notifications'
        >
          {t('user:subscriptions.actions.editNotifications')}
        </Anchor>
        ) : (
          <Anchor
            as='button'
            asVariant='secondary'
            href='/produkte'
          >
            <Icon name='lock' />
            {t('user:subscriptions.actions.upgrade')}
          </Anchor>
        )}
      </div>

      <Spacer height={2} />

      {tier == 'plus' && activeTab == 'unternehmen' && (
        <>
          <Anchor
            variant='primary'
            href='/api/proxy/user/subscriptions/export'
            target='_blank'
          >
            {t('user:subscriptions.export')}
          </Anchor>

          <Spacer height={1} />
        </>
      )}

      <TabTable
        value={activeTab}
        onTabChange={setActiveTab}
        tabs={{
          'unternehmen': {
            label: t('user:subscriptions.unternehmen.label', { count: unternehmen.length }),
            columns: t('user:subscriptions.unternehmen.columns', { returnObjects: true }),
            data: unternehmen,
            renderRow: renderUnternehmenRow,
            renderNoData: tier == 'plus' ? undefined : () => <span>{t('user:subscriptions.unternehmen.noData')}</span>,
          },
          'personen': {
            label: t('user:subscriptions.personen.label', { count: personen.length }),
            columns: t('user:subscriptions.personen.columns', { returnObjects: true }),
            data: personen,
            renderRow: renderPersonenRow,
            renderNoData: () => <span>{t('user:subscriptions.personen.noData')}</span>,
          },
        }}
      />

      {tier == 'plus' && activeTab == 'unternehmen' && <QuickSubscribe />}
    </div>
  );
};

export default SubscriptionList;
